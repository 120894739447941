import React from 'react';

import { Hero } from '../components/InnerPages/Hero';
import { InnerPageLayout } from '../components/InnerPages/Layout';

import { graphql } from 'gatsby';

interface IOptInProps {
  data: {
    ghostPage: {
      codeinjection_styles: any;
      title: string;
      html: string;
      feature_image?: string;
    };
  };
  location: any;
}

const OptIn = ({ data, location }: IOptInProps) => {
  const page = data.ghostPage;

  return (
    <InnerPageLayout articleHTML={page.html} location={location}>
      <Hero title={page.title} description="" />
    </InnerPageLayout>
  );
};

export default OptIn;

export const pageQuery = graphql`
  query {
    ghostPage(slug: { eq: "opt-in" }) {
      html
      title
    }
  }
`;
